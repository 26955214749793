import React from 'react';
import classes from "./Counter.module.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons'
import CartContext from "../../../store/cartContext";

const Counter = (props) => {

    const cartCtx = React.useContext(CartContext);

    const handleAdd = () => {
        cartCtx.addMeal(props.meal);
    }

    const handleRemove = () => {
        cartCtx.removeMeal(props.meal);
    }

    return (
        <div className={classes.container}>
            {
                props.meal.count && props.meal.count > 0 ? (
                    <>
                        <button onClick={handleRemove} className={classes.sub}><FontAwesomeIcon icon={faMinus} /></button>
                        <span className={classes.count}>{props.meal.count}</span>
                    </>
                ) : null
            }
            <button onClick={handleAdd} className={classes.add}><FontAwesomeIcon icon={faPlus} /></button>
        </div>
    );
};

export default Counter;
