import React from 'react';
import Meal from "./Meal/Meal";
import classes from "./Meals.module.css";

const Meals = (props) => {
    return (
        <div className={classes.container}>
            {props.mealsData.map(item => <Meal meal={item} key={item.id} />)}
        </div>
    );
};

export default Meals;
