import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch} from "@fortawesome/free-solid-svg-icons";
import classes from './FilterMeals.module.css'

const FilterMeals = (props) => {

    const searchHandler = (event) => {
        props.onFilter(event.target.value);
    }

    return (
        <div className={classes.container}>
            <div className={classes.inputContainer}>
                <FontAwesomeIcon
                    className={classes.searchIcon}
                    icon={faSearch}
                />
                <input
                    onChange={searchHandler}
                    className={classes.searchInput}
                    type="text"
                    placeholder={"请输入关键字"}
                />
            </div>
        </div>
    );
};

export default FilterMeals;
