import React from "react";

const CartContext = React.createContext({
    items: [],
    totalCount: 0,
    totalPrice: 0,
    addMeal: (meal) => {},
    removeMeal: (meal) => {},
})

export default CartContext;
