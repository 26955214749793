import Meals from "./components/Meals/Meals";
import FilterMeals from "./components/FilterMeals/FilterMeals";
import {useState} from "react";
import CartContext from "./store/cartContext";

// 模拟数据
// 假设这些数据是从后端获取到的
const MEALS_DATA = [
    {
        id: '1',
        title: '汉堡包',
        desc: '百分百纯牛肉配搭爽脆酸瓜洋葱粒与美味番茄酱经典滋味让你无法抵挡！',
        price: 12,
        img: '/img/meals/1.png'
    },
    {
        id: '2',
        title: '双层吉士汉堡',
        desc: '百分百纯牛肉与双层香软芝，加上松软面包及美味酱料，诱惑无人能挡！',
        price: 20,
        img: '/img/meals/2.png'
    },
    {
        id: '3',
        title: '巨无霸',
        desc: '两块百分百纯牛肉，搭配生菜、洋葱等新鲜食材，口感丰富，极致美味！',
        price: 24,
        img: '/img/meals/3.png'
    }, {
        id: '4',
        title: '麦辣鸡腿汉堡',
        desc: '金黄脆辣的外皮，鲜嫩幼滑的鸡腿肉，多重滋味，一次打动您挑剔的味蕾！',
        price: 21,
        img: '/img/meals/4.png'
    }, {
        id: '5',
        title: '板烧鸡腿堡',
        desc: '原块去骨鸡排嫩滑多汁，与翠绿新鲜的生菜和香浓烧鸡酱搭配，口感丰富！',
        price: 22,
        img: '/img/meals/5.png'
    }, {
        id: '6',
        title: '麦香鸡',
        desc: '清脆爽口的生菜，金黄酥脆的鸡肉。营养配搭，好滋味的健康选择！',
        price: 14,
        img: '/img/meals/6.png'
    }, {
        id: '7',
        title: '吉士汉堡包',
        desc: '百分百纯牛肉与香软芝士融为一体配合美味番茄醬丰富口感一咬即刻涌现！',
        price: 12,
        img: '/img/meals/7.png'
    }
];

function App() {

    // 创建 state
    const [mealsData, setMealsData] = useState(MEALS_DATA);
    const [cartData, setCartData] = useState({
        items: [],
        totalCount: 0,
        totalPrice: 0
    });

    // 过滤搜索
    const filterMeals = (keyword) => {
        const newMealsData = MEALS_DATA.filter(item => item.title.indexOf(keyword) !== -1);
        setMealsData(newMealsData);
    }

    // 点击+按钮回调
    const addMeal = (meal) => {
        const newCartData = {...cartData};

        // 判断meal在不在items
        const index = newCartData.items.indexOf(meal);
        if (index === -1) {
            meal.count = 1
            newCartData.items.push(meal);
        } else {
            newCartData.items[index].count++;
        }

        // 更新totalCount和totalPrice
        newCartData.totalCount++;
        newCartData.totalPrice += meal.price;

        // 更新state
        setCartData(newCartData);
    }

    // 点击-按钮回调
    const removeMeal = (meal) => {
        const newCartData = {...cartData};

        // 减少数量
        const index = newCartData.items.indexOf(meal);
        newCartData.items[index].count--;

        // 如果数量为0，则删除该meal
        if (newCartData.items[index].count === 0) {
            newCartData.items.splice(index, 1);
        }

        // 更新totalCount和totalPrice
        newCartData.totalCount--;
        newCartData.totalPrice -= meal.price;


        // 更新state
        setCartData(newCartData);
    }

    return (
        <CartContext.Provider value={{...cartData, addMeal, removeMeal}}>
            <div className="App">
                <FilterMeals onFilter={filterMeals} />
                <Meals mealsData={mealsData} />
            </div>
        </CartContext.Provider>
    );
}

export default App;
